import React from 'react';
import ProtoTypes from 'prop-types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {fetchOrder} from '../../redux/actions/orderActions';
import { useLocation } from 'react-router-dom';

const AppCounter = ({ percent, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();

   return  <CountdownCircleTimer
    size={30}
    isPlaying
    duration={30}
    colors={['#D81F51', '#F7B801', '#A30000', '#A30000']}
    trailColor='#D81F51'
    strokeWidth={2}
    onComplete={() => {
      // do your stuff here
      const params = new URLSearchParams(location.search);
      var orderId= params.get('orderid');
      dispatch(fetchOrder(orderId));
      return { shouldRepeat: true, delay: 1.5 } // repeat animation in 1.5 seconds
    }}
  >
        {/* {({ remainingTime }) => <span style={{color:"#FFF"}}>{remainingTime}</span>} */}
    </CountdownCircleTimer>
};
export default AppCounter;


