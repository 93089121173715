
import React, { useEffect,useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import restaurantIcon from '../../assets/store.svg'; // Import the SVG file
import driverIcon from '../../assets/driver.svg'; // Import the SVG file
import customerIcon from '../../assets/home.svg'; // Import the SVG file
import config from './config';
const TrackingMap = ({order,driver}) => {

const [icons, setIcons] = useState({
  restaurant: '',
  driver: '',
  customer: ''
});

const containerStyle = {
  width: '100%',
  height: '300px'
};

const center = {
  lat: 51.505,
  lng: -0.09
};

useEffect(() => {
  const loadIcons = async () => {
    const restaurantIconUrl = await fetchSvgContent(restaurantIcon);
    const driverIconUrl = await fetchSvgContent(driverIcon);
    const customerIconUrl = await fetchSvgContent(customerIcon);

    setIcons({
      restaurant: restaurantIconUrl,
      driver: driverIconUrl,
      customer: customerIconUrl
    });
  };

  loadIcons();
}, []);

// Utility function to convert SVG to data URL and scale it
const svgToDataUrl = (svg) => {
  const svgElement = new DOMParser().parseFromString(svg, 'image/svg+xml').documentElement;
  svgElement.setAttribute('width', '40'); // Set the desired width
  svgElement.setAttribute('height', '40'); // Set the desired height
  const svgString = new XMLSerializer().serializeToString(svgElement);
  return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgString)}`;
};

const getCenterLocation=(driver,order)=>{
   if(driver!=null){
        return {
          lat:Number(driver.latitudeStr),
          lng:Number(driver.longitudeStr)
          }
        }else if(order){
          if(order.customerAddress.latitudeStr!=null){
            return {
              lat:Number(order.customerAddress.latitudeStr),
              lng:Number(order.customerAddress.longitudeStr)
              }
          }else {
            return {
              lat:Number(order.clientLocation.locationAddress.latitudeStr),
              lng:Number(order.clientLocation.locationAddress.longitudeStr)
            }
           }

        }
}

// Fetch the SVG content (for the sake of example, we use a fetch call)
const fetchSvgContent = async (url) => {
  const response = await fetch(url);
  const svgText = await response.text();
  return svgToDataUrl(svgText);
};
    return (
        <div style={{ borderRadius: '15px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <LoadScript googleMapsApiKey="AIzaSyBJqLOLs7S5jhQMJBGN4xA9TEVacFwvZm0">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={getCenterLocation(driver,order)}
            zoom={config.selectedZoom}
            options={{
              gestureHandling: 'greedy'  // Ensures touch events are captured for panning
            }}
          >
           {icons.restaurant && (
              <Marker
                 position={
                  {
                    lat:Number(order.clientLocation.locationAddress.latitudeStr),
                    lng:Number(order.clientLocation.locationAddress.longitudeStr)}
                  }
                  icon={{
                  url: icons.restaurant,
                  scaledSize: { width: 40, height: 40 }
                }}
              />
            )}
            {icons.driver &&  driver!=null &&(
              <Marker
                position={ 
                  {
                  lat:Number(driver.latitudeStr),
                  lng:Number(driver.longitudeStr)
                  }
                  }
                icon={{
                  url: icons.driver,
                  scaledSize: { width: 40, height: 40 }
                }}
              />
            )}
            {icons.customer && (
              <Marker
                position={ {
                  lat:Number(order.customerAddress.latitudeStr),
                  lng:Number(order.customerAddress.longitudeStr)
                  }}
                icon={{
                  url: icons.customer,
                  scaledSize: { width: 40, height: 40 }
                }}
              />
            )}
          </GoogleMap>
        </LoadScript>
      </div>
    )
}
export default TrackingMap;