// src/containers/Payment.js
import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Typography, Row, Col, Divider, Space, Steps, Tooltip, Spin, Alert, Button } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, CarOutlined, HomeOutlined } from '@ant-design/icons';
import { FaUtensils, FaHome, FaCarAlt } from 'react-icons/fa';
import { verifyPayment } from '../../redux/actions/orderActions';
import logo from '../../assets/logo.png'; // Ensure you have a logo in your assets folder
import AppCounter from '../../components/appCounter';
import TrackingMap from '../../components/map';
import { useLocation } from 'react-router-dom';
import formatDate from '../../helper/dateHelper'
import {LoadingOutlined} from '@ant-design/icons';
import deliveryStatus from '../../helper/orderStatus';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { FaRegCircleXmark } from "react-icons/fa6";
import { BsXCircle } from "react-icons/bs";

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Step } = Steps;

const Payment = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const orderState = useSelector((state) => state.order);
    const { loading, order, error,driver } = orderState;
    const [isSuccess, setIsSuccess] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        var paymentId= params.get('paymentId');
        if(paymentId){
         dispatch(verifyPayment(paymentId)); // Replace '123' with actual order ID
        }
    }, [dispatch]);

    useEffect(() => {
        if(order==null){
          return ;
        }
      
     if(order.paymentMethodId == 40){
              setIsSuccess(true);
       }else{
              setIsSuccess(false);
       }
      }, [order]);

    useEffect(() => {
    if(error!=null)
        setIsSuccess(false);
    }
    , [error]);

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
    <Header style={{ backgroundColor: '#D81F51', padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Col >
      <img src={logo} alt="Logo" style={{ width: 50, height: 50, marginTop:"20px"}} />
    </Col>
    <Col span={18}>
      <Title level={3} style={{ color: 'white', margin: '0' }}>Order Tracking</Title>
      </Col>
    </Header>
    <Content style={{ padding: '20px', backgroundColor: '#fff' }}> 
    {loading ||  isSuccess == null ? <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} /> :
    <>
    <Row>
        <Col span={24}>
            {isSuccess ? 
                <IoIosCheckmarkCircleOutline style={{ fontSize: '200px', color: 'green' , marginTop: '50px', width:'100%' , textAlign:'center'}} />:
                <BsXCircle style={{ fontSize: '200px', color: '#D81F51' , marginTop: '50px', width:'100%' , textAlign:'center'}} />
            }
        </Col>
    </Row>
    <Row style={{marginTop:"50px"}}>
        <Col span={24}>
        {isSuccess ? 
        <Title level={4} style={{ color: '#333', margin: '0' ,textAlign:'center'}}>Your payment has been successfuly processed</Title>:
        <Title level={4} style={{ color: '#333', margin: '0' ,textAlign:'center'}}>Your payment was failed to be processed</Title>
        }
        </Col>
    </Row>
    <Divider />
    </>     
    }
    {/* <Row justify="center">
        <Col>
            <Button type="primary" style={{ background: "#D81F51", margin: '0' }}>Pay online</Button>
        </Col>
     </Row> */}
    </Content>
  </Layout>
  );
};

export default Payment;
