// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OrderTracking from './containers/tracking';
import Payment from './containers/payment';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<OrderTracking />} />
        <Route path="/payment" element={<Payment />} /> {/* Add the payment route */}
      </Routes>
    </Router>
  );
};
export default App;