import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';

// Extend dayjs with the plugins
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(duration);

function formatDate(date) {
    const now = dayjs();
    const givenDate = dayjs(date);
    const diffMinutesPast = now.diff(givenDate, 'minute');
    const diffMinutesFuture = givenDate.diff(now, 'minute');
    const diffHoursPast = now.diff(givenDate, 'hour', true);

    if (diffHoursPast < 2 && diffMinutesPast >= 0) {
        // If the time difference is less than 2 hours in the past, show exact minutes
        if (diffMinutesPast < 1) {
            return "just now";
        } else {
            return `${diffMinutesPast} mins ago`; // "53 mins ago" or "5 mins ago"
        }
    } else if (diffMinutesFuture >= 0 && diffMinutesFuture < 30) {
        // If the time difference is less than 30 minutes in the future, show exact minutes
        return `in ${diffMinutesFuture} mins`; // "in 29 mins" or "in 5 mins"
    } else if (givenDate.isSame(now, 'day')) {
        // If it is today but more than 2 hours in the past, show "Today at [time]"
        return `Today at ${givenDate.format('h:mmA')}`; // "Today at 5:43PM"
    } else if (givenDate.isSame(now.add(1, 'day'), 'day')) {
        // If it is tomorrow, show "Tomorrow at [time]"
        return `Tomorrow at ${givenDate.format('h:mmA')}`; // "Tomorrow at 5:43PM"
    } else if (givenDate.isSame(now.subtract(1, 'day'), 'day')) {
        // If it was yesterday, show "Yesterday at [time]"
        return `Yesterday at ${givenDate.format('h:mmA')}`; // "Yesterday at 5:43PM"
    } else if (givenDate.isAfter(now)) {
        // For future dates beyond tomorrow, show "D. MMMM at h:mmA"
        return givenDate.format('D. MMMM [at] h:mmA'); // "7. April at 5:43PM"
    } else {
        // For older dates, show "D. MMMM at h:mmA"
        return givenDate.format('D. MMMM [at] h:mmA'); // "7. April at 5:43PM"
    }
}


export default formatDate;