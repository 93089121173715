// src/reducers/orderReducer.js
import { fetchPaymentCreationSuccess } from '../actions/orderActions';
import {
    FETCH_ORDER_REQUEST,
    FETCH_ORDER_SUCCESS,
    FETCH_ORDER_FAILURE,
    FETCH_DRIVER_REQUEST,
    FETCH_DRIVER_SUCCESS,
    FETCH_DRIVER_FAILURE,
    FETCH_PAYMENT_VERIFY_REQUEST,
    FETCH_PAYMENT_VERIFY_SUCCESS,
    FETCH_PAYMENT_VERIFY_FAILURE,
    FETCH_PAYMENT_CREATION_REQUEST,
    FETCH_PAYMENT_CREATION_SUCCESS,
    FETCH_PAYMENT_CREATION_FAILURE
  } from '../actions/orderActions';
  
  const initialState = {
    loading: false,
    order: null,
    driver:null,
    error: null,
  };
  
  const orderReducer = (state = initialState, action) => {
    switch (action.type) {
      // Order
      case FETCH_ORDER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ORDER_SUCCESS:
        return {
          ...state,
          loading: false,
          order: action.payload,
          error: null,
        };
      
        case FETCH_ORDER_FAILURE:
        return {
          ...state,
          loading: false,
          order: null,
          error: action.payload,
        };

        // Driver
        case FETCH_DRIVER_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case FETCH_DRIVER_SUCCESS:
          return {
            ...state,
            loading: false,
            driver: action.payload,
            error: null,
          };
        case FETCH_DRIVER_FAILURE:
          return {
            ...state,
            loading: false,
            driver: null,
            error: action.payload,
          };

        // Payment Creation
        case FETCH_PAYMENT_CREATION_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case FETCH_PAYMENT_CREATION_SUCCESS:
          return {
            ...state,
            loading: false,
            order: action.payload,
            error: null,
          };
        case FETCH_PAYMENT_CREATION_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };

        //  Payment Failed
        case FETCH_PAYMENT_VERIFY_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case FETCH_PAYMENT_VERIFY_SUCCESS:
          return {
            ...state,
            loading: false,
            order: action.payload,
            error: null,
          };
        case FETCH_PAYMENT_VERIFY_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          };


      default:
        return state;
    }
  };
  
  export default orderReducer;
  