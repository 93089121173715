import { Layout, Typography, Row, Col, Divider, Space, Steps, Tooltip, Spin, Alert } from 'antd';
import logo from '../../../assets/logo.png'; // Ensure you have a logo in your assets folder

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Step } = Steps;

const containerStyle = {
  width: '100%',
  height: '300px'
};

const center = {
  lat: 51.505,
  lng: -0.09
};


const NotFound = () => {

    return (
        <Layout style={{ minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <Header style={{ backgroundColor: '#D81F51', padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={logo} alt="Logo" style={{ width: 50, height: 50, marginRight: '20px' }} />
        <Title level={3} style={{ color: 'white', margin: '0' }}>Order Tracking</Title>
      </Header>
      <Content style={{ padding: '20px', backgroundColor: '#fff' }}>
        <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Title level={5}>Pickup</Title>
              <Text>"test"</Text>
            </Col>
            <Col span={12}>
              <Title level={5}>Deliver</Title>
              <Text>"test"</Text>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" align="end">
            <Col offset={18}>
              <Title level={4} style={{ margin: 0 }}>12</Title>
              <Text type="secondary">"Cash"</Text>
            </Col>
          </Row>
        </div>
      </Content>
      </Layout>
    )
}

export default NotFound;