// src/actions/orderActions.js
import apiConfig from '../../service/ApiConfig';
import delievryStatus from '../../helper/orderStatus'
export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const FETCH_DRIVER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_DRIVER_SUCCESS = 'FETCH_DRIVER_SUCCESS';
export const FETCH_DRIVER_FAILURE = 'FETCH_DRIVER_FAILURE';

export const FETCH_PAYMENT_VERIFY_REQUEST = 'FETCH_PAYMENT_VERIFY_REQUEST';
export const FETCH_PAYMENT_VERIFY_SUCCESS = 'FETCH_PAYMENT_VERIFY_SUCCESS';
export const FETCH_PAYMENT_VERIFY_FAILURE = 'FETCH_PAYMENT_VERIFY_FAILURE';

export const FETCH_PAYMENT_CREATION_REQUEST = 'FETCH_PAYMENT_CREATION_REQUEST';
export const FETCH_PAYMENT_CREATION_SUCCESS = 'FETCH_PAYMENT_CREATION_SUCCESS';
export const FETCH_PAYMENT_CREATION_FAILURE = 'FETCH_PAYMENT_CREATION_FAILURE';

const BaseUrl = "https://aseertimedelivery.com";
// const BaseUrl = "http://localhost:40015";


export const fetchOrderRequest = () => ({
  type: FETCH_ORDER_REQUEST,
});

export const fetchOrderSuccess = (order) => ({
  type: FETCH_ORDER_SUCCESS,
  payload: order,
});

export const fetchOrderFailure = (error) => ({
  type: FETCH_ORDER_FAILURE,
  payload: error,
});


export const fetchDriverRequest = () => ({
  type: FETCH_DRIVER_REQUEST,
});

export const fetchDriverSuccess = (driver) => ({
  type: FETCH_DRIVER_SUCCESS,
  payload: driver,
});

export const fetchDriverFailure = (error) => ({
  type: FETCH_DRIVER_FAILURE,
  payload: error,
});


export const fetchPaymentVerifyRequest = () => ({
  type: FETCH_PAYMENT_VERIFY_REQUEST,
});

export const fetchPaymentVerifySuccess = (order) => ({
  type: FETCH_PAYMENT_VERIFY_SUCCESS,
  payload: order,
});

export const fetchPaymentVerifyFailure = (error) => ({
  type: FETCH_PAYMENT_VERIFY_FAILURE,
  payload: error,
});


export const fetchPaymentCreationRequest = () => ({
  type: FETCH_PAYMENT_CREATION_REQUEST,
});

export const fetchPaymentCreationSuccess = (order) => ({
  type: FETCH_PAYMENT_CREATION_SUCCESS,
  payload: order,
});

export const fetchPaymentCreationFailure = (error) => ({
  type: FETCH_PAYMENT_CREATION_FAILURE,
  payload: error,
});

export const fetchOrder = (orderId) => async (dispatch) => {
  dispatch(fetchOrderRequest());
  try {
    const response = await apiConfig.get(`${BaseUrl}/order/${orderId}`);
    dispatch(fetchOrderSuccess(response.data));
    if(response.data.deliveryStatusId == delievryStatus.STARTED_TRACKING || 
      response.data.deliveryStatusId == delievryStatus.PICKED ||
      response.data.deliveryStatusId == delievryStatus.REACHED_CUSTOMER
    ){
    dispatch(fetchDriver(response.data.driverId));
    }
  } catch (error) {
    dispatch(fetchOrderFailure(error.message));
  }
};

export const fetchDriver = (driverId) => async (dispatch) => {
  dispatch(fetchDriverRequest());
  try {
    const response = await apiConfig.get(`${BaseUrl}/driver/${driverId}`);
    dispatch(fetchDriverSuccess(response.data));
  } catch (error) {
    dispatch(fetchDriverFailure(error.message));
  }
};

export const createPayment = (model) => async (dispatch) => {
  dispatch(fetchPaymentVerifyRequest());
  try {
    const response = await apiConfig.put(`${BaseUrl}/order/paymentlink`,model);
    dispatch(fetchPaymentVerifySuccess(response.data));
  } catch (error) {
    dispatch(fetchPaymentVerifyFailure(error.message));
  }
};

export const verifyPayment = (paymentId) => async (dispatch) => {
  dispatch(fetchPaymentVerifyRequest());
  try {
    const response = await apiConfig.get(`${BaseUrl}/payment/verify?paymentId=${paymentId}`);
    dispatch(fetchPaymentVerifySuccess(response.data));
  } catch (error) {
    dispatch(fetchPaymentVerifyFailure(error.message));
  }
};
