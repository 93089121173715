// src/components/OrderTracking.js
import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Typography, Row, Col, Divider, Space, Steps, Tooltip, Spin, Alert, Button } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, CarOutlined, HomeOutlined } from '@ant-design/icons';
import { FaUtensils, FaHome, FaCarAlt } from 'react-icons/fa';
import { fetchOrder,createPayment } from '../../redux/actions/orderActions';
import logo from '../../assets/logo.png'; // Ensure you have a logo in your assets folder
import AppCounter from '../../components/appCounter';
import NotFound from './notFound';
import TrackingMap from '../../components/map';
import { useLocation } from 'react-router-dom';
import formatDate from '../../helper/dateHelper'
import {LoadingOutlined} from '@ant-design/icons';
import deliveryStatus from '../../helper/orderStatus';
import orderStatus from '../../helper/orderStatus';

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { Step } = Steps;

const OrderTracking = () => {
  const dispatch = useDispatch();
  const orderState = useSelector((state) => state.order);
  const { loading, order, error,driver } = orderState;
  const location = useLocation();
  const [step, setStep] = useState(0);
  const [paymentClicked,setPaymentClicked] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var orderId= params.get('orderid');
    dispatch(fetchOrder(orderId)); // Replace '123' with actual order ID
  }, [dispatch]);

  useEffect(() => {
    if(order==null){
      setStep(0);
      return ;
    }
    if(paymentClicked){
      if(order.paymentMethodId == 10){
        if(order.paymentUrl!=null){
          window.location.href = order.paymentUrl;
        }
      }

    }
    if(order.deliveryStatusId == deliveryStatus.PENDING)
      {
        setStep(0);
      }
    else if(order.deliveryStatusId == deliveryStatus.PICKED 
      || order.deliveryStatusId == deliveryStatus.STARTED_TRACKING 
      || order.deliveryStatusId == deliveryStatus.REACHED_CUSTOMER 
      | order.deliveryStatusId == deliveryStatus.REACHED_STORE ||
      order.deliveryStatusId == deliveryStatus.ACCEPTED )
      {
        setStep(1);
      }
    else if(order.deliveryStatusId == deliveryStatus.DELIVERED)
      {
        setStep(2);
      }
  }, [order]);

  // if (loading) {
  //   return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  // }

  // if (error) {
  //   return (
  //     <NotFound/>
  //   )
  // }
 
  return (
     order==null ? <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} /> :
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f9f9f9' }}>
      <Header style={{ backgroundColor: '#D81F51', padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Col >
        <img src={logo} alt="Logo" style={{ width: 50, height: 50, marginTop:"20px"}} />
      </Col>
      <Col span={18}>
        <Title level={3} style={{ color: 'white', margin: '0' }}>Order Tracking</Title>
        </Col>
          <Col >
            {order.deliveryStatusId!=deliveryStatus.DELIVERED &&
          <AppCounter/>}
        </Col>
      </Header>
      <Content style={{ padding: '20px', backgroundColor: '#fff' }}>
        <TrackingMap order={order} driver={driver} />
        <div style={{ marginTop: '20px', padding: '20px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Steps direction="vertical" current={step} className="custom-steps">
            <Step 
              title={
                <Tooltip title={formatDate(order.promisedDeliveryDateUtc)}>
                  <span style={{color:'#D81F51'}}>Order Placed</span>
                </Tooltip>
              } 
              description={
                <Space direction="vertical">
                  <Text>{order.client.nameEn}</Text>
                  <Text>{order.clientLocation.locationAddress.addressLine1}</Text>
                  <Text type="secondary">{formatDate(order.promisedDeliveryDateUtc)}</Text>
                </Space>
              } 
              icon={<EnvironmentOutlined className="step-icon" style={{ color: '#D81F51' }} />} 
            />
            <Step 
              title={
                <Tooltip title={order?.pickedTime}>
                  <span style={{color:'#D81F51'}}>Order Status </span>
                </Tooltip>
              } 
              description={
                order.driver ? (
                  <>
                  <Text >{orderStatus.getStatusMessage(order)}...</Text>
                  {order.deliveryStatusId!=deliveryStatus.DELIVERED &&
                  <LoadingOutlined/>}
                  <br/>
                  <Space direction="vertical">
                    <Text>{order?.driver.firstName} {order?.driver.lastName}</Text> 
                    <Text type="secondary">{order?.driver.mobile}</Text>
                  </Space>
                  </>
                ) : 
                  <>
                  <Text>{orderStatus.getStatusMessage(order)}</Text>
                  <LoadingOutlined/>
                  </>
                 
              } 
              icon={<CarOutlined className="step-icon" style={{ color:'#D81F51'  }} />} 
            />
            <Step 
              title={
                <Tooltip title={`ETA: ${order?.ETA}`}>
                  <span style={{color:'#D81F51'}} >Destination</span>
                </Tooltip>
              } 
              description={
                <Space direction="vertical">
                  <Text>{order.customerFirstName} {order.customerLastName} | {order.customerMobileNo}</Text>
                  <Text>{order.customerAddress.addressLine1}</Text>
                  {/* <Text type="secondary">ETA: {order?.ETA}</Text> */}
                </Space>
              } 
              icon={<HomeOutlined className="step-icon" style={{ color: '#D81F51' }} />} 
            />
          </Steps>
          <Divider />
          <Row gutter={16}>
            <Col span={12}>
              <Title level={5}>Pickup</Title>
              <Text>{order.clientLocation.locationAddress.addressLine1}</Text>
            </Col>
            <Col span={12}>
              <Title level={5}>Deliver</Title>
              <Text>{order.customerAddress.addressLine1}</Text>
            </Col>
          </Row>
          <Divider />
          {order.driver   ? (
            <>
              <Row align="middle" gutter={16} style={{ marginBottom: '20px' }}>
                <Col>
                  <FaCarAlt size={50} color="#D81F51" />
                </Col>
                <Col>
                  <Space direction="vertical">
                    <Text strong>{order?.driver.firstName} {order?.driver.lastName}</Text>
                    <Text type="secondary">
                    <PhoneOutlined />
                    {order?.driver?.mobile ? (
                      <a href={`tel:${order.driver.mobile}`} style={{ marginLeft: '5px' }}>
                        {order.driver.mobile}
                      </a>
                    ) : "No mobile number"}
                  </Text>
                  </Space>
                </Col>
              </Row>
              <Divider />
            </>
          ) : null}
          <Row justify="space-between" align="end">
            <Col span={6}>
            {order.paymentMethodId==10 &&
            order.deliveryStatusId!=deliveryStatus.CANCLED &&
            <Button type="primary" style={{background:"#D81F51"}} onClick={()=>{
              setPaymentClicked(true);
              dispatch(createPayment({
                orderId:order.id,
                totalAmount:order.totalAmount,
              }))
            }}>Pay online</Button>
            }
            </Col>
            <Col offset={6} span={6}>
              <Title level={4} style={{ margin: 0 }}>{order?.totalAmount} KWD</Title>
              <Text type="secondary">{order?.paymentMethod}</Text>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default OrderTracking;
