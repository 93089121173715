import { createStore, applyMiddleware, combineReducers,compose } from 'redux';
import { thunk } from 'redux-thunk'; // Change this line to import thunk correctly
import orderReducer from '../redux/reducers/orderReducer';

const rootReducer = combineReducers({
  order: orderReducer,
});

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk))
);

export default store;