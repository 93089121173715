

 const orderStatus = {
    PENDING: 10,
    ASSIGNED: 20,
    ACCEPTED: 30,
    REJECTED_BY_DRIVER: 35,
    REACHED_STORE:40,
    PICKED:50,
    STARTED_TRACKING:60,
    REACHED_CUSTOMER:70,
    DELIVERED:80,
    CANCLED:90,
    REJECTED_BY_CUSTOMER:95,

    getOrderStatusTitle:(status)=>{

        switch (status)
        {
            case orderStatus.PENDING:
            return "Pending";

            case orderStatus.ASSIGNED:
            return "Dispatched";

            case orderStatus.ACCEPTED:
            return "Accepted";

            case orderStatus.REJECTED_BY_DRIVER:
            return "Rejected By Driver";

            case orderStatus.REACHED_STORE:
            return "Reached Store";

            case orderStatus.PICKED:
            return "Picked";

            case orderStatus.STARTED_TRACKING:
            return "On The Way";

            case orderStatus.REACHED_CUSTOMER:
            return "Reached Customer";

            case orderStatus.DELIVERED:
            return "Delivered";

            case orderStatus.CANCLED:
            return "Canceled";

            case orderStatus.REJECTED_BY_CUSTOMER:
            return "Rejected by Customer";
        }        
    },

 getStatusColor:(DeliveryStatus)=>{
    switch(DeliveryStatus)
    {
        case orderStatus.PENDING:
        return "#f50";

        case orderStatus.ASSIGNED:
        case orderStatus.ACCEPTED:
        case orderStatus.REJECTED_BY_DRIVER:
        case orderStatus.REACHED_STORE:
        case orderStatus.PICKED:
        case orderStatus.STARTED_TRACKING:
        case orderStatus.REACHED_CUSTOMER:
        return "#108ee9";
        case orderStatus.DELIVERED:
        return "#87d068";
        case orderStatus.CANCLED:
        case orderStatus.REJECTED_BY_CUSTOMER:
        case orderStatus.REJECTED_BY_DRIVER:
        return "#f55";
    }
    },

    getStatusMessage:(order)=>{
        switch (order.deliveryStatusId)
        {
            case orderStatus.PENDING:
            return "Being Prepard ...";

            case orderStatus.ASSIGNED:
            return "Driver Dispatched";

            case orderStatus.ACCEPTED:
            return "Driver Dispatched";

            case orderStatus.REJECTED_BY_DRIVER:
            return "-";

            case orderStatus.REACHED_STORE:
            return "Driver Reached Store";

            case orderStatus.PICKED:
            return "Picked";

            case orderStatus.STARTED_TRACKING:
            return "On The Way ...";

            case orderStatus.REACHED_CUSTOMER:
            return "Reached Destination";

            case orderStatus.DELIVERED:
            return "Delivered";

            case orderStatus.CANCLED:
            return "Canceled";

            case orderStatus.REJECTED_BY_CUSTOMER:
            return "Rejected by customer";
        }
    }
 }

 export default orderStatus;

  
    