// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import statement
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container); // Create a root.

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
